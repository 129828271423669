<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>

          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>

          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">

            <CRow>

              <CCol col="6">
                <CInput
                    label="İsim"
                    horizontal
                    v-model="data.name"
                />
              </CCol>

              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>

              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>

            </CRow>

            <CRow>

              <CCol>

                <CInput
                    label="Başlangıç Tarihi"
                    horizontal
                    type="date"
                    v-model="data.startTime"
                />

              </CCol>

              <CCol>

                <CInput
                    label="Bitiş Tarihi"
                    horizontal
                    type="date"
                    v-model="data.endTime"
                />

              </CCol>
              
            </CRow>

            <CRow>

              <CCol>

                <CSelect
                  label="İçerik Tipi"
                  placeholder="İçerik Tipi"
                  horizontal
                  :options="contentTypes"
                  :value.sync="data.contentType"
                >
                </CSelect>

              </CCol>

              <CCol>
                <CRow>
                  <CCol>
                    <CSelect
                      label="Aktif"
                      horizontal
                      :options="yesNoList"
                      :value.sync="data.activeFlag"
                    />
                  </CCol>
                </CRow>
              </CCol>

            </CRow>

            <CRow>

              <CCol>
                <CRow>
                  <CCol>
                    <CSelect
                        label="Lokasyon"
                        type="text"
                        placeholder="Seçiniz"
                        :options="publishTypes"
                        :value.sync="data.publishType"
                        horizontal
                    />
                  </CCol>
                </CRow>
              </CCol>

            </CRow>

          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "ContentFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       computed:{

          contentTypes: function() {
              let data = [{value: undefined, label: 'İçerik Tipi Seçin'}]
              this.$store.getters.contentTypes.map(r => data.push({value:r.id, label: r.name}))
              return data
          },

          publishTypes: function() {
              let data = ['Lokasyon Seçin']
              this.$store.getters.contentPublishTypes.map(r => data.push({value:r.type, label: r.friendlyType}))
              return data
          },
          
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               yesNoList: [
                {label: 'Seçiniz', value: ''},
                { label: 'Evet', value: true},
                { label: 'Hayır', value: false},
              ]
           }
       },
       methods:{
        
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>